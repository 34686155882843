<template>
  <card
    :content="content"
    :schema="getSchemaFeedMills"
    :form-data="getDataFeedMills"
    :editable="getEditableFeedMills"
    @update-form="updateFeedMills"
    @submitAction="addFeedMills"
  />
</template>

<script>
import Card from "../components/Card";
import confFeedMills from "../conf/confFeedMills.json";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FeedMills",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confFeedMills.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("feedMills/loadFeedMills");
  },
  computed: {
    ...mapGetters("feedMills", [
      "getDataFeedMills",
      "getSchemaFeedMills",
      "getEditableFeedMills"
    ])
  },
  methods: {
    ...mapActions("feedMills", [
      "updateFeedMills",
      "addFeedMills",
      "loadFeedMills"
    ])
  }
};
</script>

<style scoped></style>
